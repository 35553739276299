import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import GoogleLogin from "./GoogleLogin"; // Your Google Login component
import LoginCallback from "./LoginCallback"; // Your component to handle login callback
import Dashboard from "./Dashboard"; // Your main application dashboard component
import AddressForm from './AddressForm'; // Component for address submission
import SubjectSelection from './SubjectSelection';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<GoogleLogin />} />
        <Route path="/login" element={<LoginCallback />} />
        <Route path="/address" element={<AddressForm />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/subject" element={<SubjectSelection />} />
        {/* Add more routes as needed */}
      </Routes>
    </Router>
  );
};

export default App;
