import React, { useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

const AddressForm = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { userId, name, profile_picture } = state;

  const [address, setAddress] = useState({
    address_line1: "",
    city: "",
    state: "",
    postal_code: "",
    country: "India",
    block: "",
    district: "",
    division: "",
    region: "",
    post_office_name: "",
  });

  const [phoneNumber, setPhoneNumber] = useState("");
  const [pincode, setPincode] = useState("");
  const [postOffices, setPostOffices] = useState([]);
  const [selectedPostOffice, setSelectedPostOffice] = useState(null);
  const [loading, setLoading] = useState(false); // Loading state

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddress((prev) => ({ ...prev, [name]: value }));
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handlePincodeChange = (e) => {
    setPincode(e.target.value);
  };

  const fetchPostOffices = async () => {
    setLoading(true); // Set loading to true when fetching starts
    try {
      const response = await axios.get(
        `https://api.postalpincode.in/pincode/${pincode}`
      );
      const data = response.data;

      if (data[0].Status === "Success" && data[0].PostOffice.length > 0) {
        setPostOffices(data[0].PostOffice);
      } else {
        console.error("No post offices found for this pincode.");
        setPostOffices([]);
      }
    } catch (error) {
      console.error("Error fetching post offices:", error);
      setPostOffices([]);
    } finally {
      setLoading(false); // Set loading to false after fetching completes
    }
  };

  const handlePincodeSubmit = (e) => {
    e.preventDefault();
    fetchPostOffices();
  };

  const handlePostOfficeSelect = (postOffice) => {
    setSelectedPostOffice(postOffice);
    setAddress((prev) => ({
      ...prev,
      city: postOffice.Name,
      state: postOffice.State,
      postal_code: postOffice.Pincode,
      block: postOffice.Block,
      district: postOffice.District,
      division: postOffice.Division,
      region: postOffice.Region,
      post_office_name: postOffice.Name,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post("https://nest.examtech.org:8894/api/addresses", {
        userId,
        phoneNumber,
        ...address,
      });
      navigate("/subject");
    } catch (error) {
      console.error("Error submitting address:", error);
    }
  };

  return (
    <div className="container-fluid d-flex align-items-center justify-content-center vh-100">
      <div
        className="card p-4 shadow border-0"
        style={{ maxWidth: "800px", width: "100%" }}
      >
        <h1
          className="mb-4"
          style={{ fontSize: "24px", fontWeight: "bold" }}
        >{`Welcome, ${name}`}</h1>
        <img
          src={profile_picture}
          alt="Profile"
          className="img-fluid rounded-circle mb-4"
          style={{ width: "100px", height: "100px" }}
        />

        <div className="row mb-4">
          <div className="col-md-6">
            <form onSubmit={handlePincodeSubmit}>
              <div className="form-group mb-3">
                <input
                  type="text"
                  placeholder="Enter Pincode"
                  value={pincode}
                  onChange={handlePincodeChange}
                  required
                  className="form-control"
                />
              </div>
              <button type="submit" className="btn btn-primary w-100">
                Fetch Post Offices
              </button>
            </form>
            {loading && (
              <p className="text-center mt-3">Loading post offices...</p>
            )}{" "}
            {/* Loading message */}
            {postOffices.length > 0 && !loading && (
              <div className="mt-2">
                <h2 className="h6">Select Post Office:</h2>
                <ul className="list-group">
                  {postOffices.map((postOffice, index) => (
                    <li
                      key={index}
                      className="list-group-item list-group-item-action"
                      onClick={() => handlePostOfficeSelect(postOffice)}
                    >
                      {postOffice.Name} ({postOffice.Block})
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>

          <div className="col-md-6">
            <form onSubmit={handleSubmit}>
              {[
                {
                  label: "Address Line 1",
                  name: "address_line1",
                  type: "text",
                  required: true,
                },
                {
                  label: "Phone Number",
                  name: "phoneNumber",
                  type: "text",
                  required: true,
                },
                { label: "City", name: "city", type: "text", required: true },
                { label: "State", name: "state", type: "text", required: true },
                {
                  label: "Postal Code",
                  name: "postal_code",
                  type: "text",
                  required: true,
                },
                {
                  label: "Country",
                  name: "country",
                  type: "text",
                  value: address.country,
                  readOnly: true,
                },
                { label: "Block", name: "block", type: "text", required: true },
                {
                  label: "District",
                  name: "district",
                  type: "text",
                  required: true,
                },
                {
                  label: "Division",
                  name: "division",
                  type: "text",
                  required: true,
                },
                {
                  label: "Region",
                  name: "region",
                  type: "text",
                  required: true,
                },
              ].map(({ label, name, type, required, readOnly }, index) => (
                <div className="form-group mb-3 row" key={index}>
                  <label
                    className="col-sm-4 col-form-label"
                    style={{ fontSize: "14px" }}
                  >
                    {label}:
                  </label>
                  <div className="col-sm-8">
                    <input
                      type={type}
                      name={name}
                      placeholder={label}
                      value={
                        type === "text" && name === "phoneNumber"
                          ? phoneNumber
                          : address[name]
                      }
                      onChange={
                        name === "phoneNumber"
                          ? handlePhoneNumberChange
                          : handleChange
                      }
                      required={required}
                      readOnly={readOnly}
                      className="form-control"
                      style={{ fontSize: "14px" }}
                    />
                  </div>
                </div>
              ))}

              <button type="submit" className="btn btn-success w-100">
                Submit Address
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddressForm;
