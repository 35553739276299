import React, { useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const LoginCallback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");
    
    console.log(code);

    const fetchUserData = async () => {
      try {
        const response = await axios.post(
          "https://nest.examtech.org:8894/login",
          { code }
        );

        // Assuming the response includes user info and a flag for address requirement
        const { userId, name, profile_picture, addressRequired } =
          response.data;

        if (addressRequired) {
          console.log("login success");
          // Navigate to address form page
          navigate("/address", { state: { userId, name, profile_picture } });
        } else {
          // Navigate to dashboard or home page
          navigate("/dashboard");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        // Handle error, maybe redirect to an error page or display a message
      }
    };

    if (code) {
      fetchUserData();
    } else {
      // Handle the case where there's no code
      console.error("No code found in the URL");
    }
  }, [navigate]);

  return <div>Loading...</div>;
};

export default LoginCallback;
