import React, { useState, useEffect } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "./SubjectSelection.css";

const SubjectSelection = () => {
  const bookSets = [
    { name: "Complete PUC Set", price: 1300, type: "set" },
    { name: "1st PUC Set", price: 600, type: "set" },
    { name: "2nd PUC Set", price: 800, type: "set" },
  ];

  const singleBooks = [
    { name: "Physics", price: 100, type: "single" },
    { name: "Chemistry", price: 100, type: "single" },
    { name: "Maths", price: 100, type: "single" },
    { name: "Biology", price: 100, type: "single" },
  ];

  const [bookCounts, setBookCounts] = useState(
    [...bookSets, ...singleBooks].reduce((acc, subject) => {
      acc[subject.name] = 0;
      return acc;
    }, {})
  );

  const [selectedCards, setSelectedCards] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    calculateTotalPrice();
  }, [bookCounts]);

  const handleCardClick = (subject) => {
    if (selectedCards.includes(subject.name)) {
      setSelectedCards((prevSelected) =>
        prevSelected.filter((name) => name !== subject.name)
      );
      setBookCounts((prevCounts) => ({
        ...prevCounts,
        [subject.name]: 0,
      }));
    } else {
      setSelectedCards((prevSelected) => [...prevSelected, subject.name]);
      setBookCounts((prevCounts) => ({
        ...prevCounts,
        [subject.name]: 1,
      }));
    }
  };

  const handleBookCountChange = (subject, value) => {
    setBookCounts((prevCounts) => ({
      ...prevCounts,
      [subject]: parseInt(value) || 1,
    }));
  };

  const calculateTotalPrice = () => {
    let total = 0;
    selectedCards.forEach((subject) => {
      const subjectData = [...bookSets, ...singleBooks].find(
        (s) => s.name === subject
      );
      total += bookCounts[subject] * subjectData.price;
    });
    setTotalPrice(total);
  };

  const handlePayment = () => {
    fetch("https://nest.examtech.org:8894/create-order", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ amount: totalPrice, promoCode }),
    })
      .then((response) => response.json())
      .then((order) => {
        const options = {
          key: "rzp_test_9VXfUlkZzNT80u",
          amount: order.amount,
          currency: order.currency,
          name: "nest",
          description: "Book Purchase",
          order_id: order.id,
          handler: function (response) {
            fetch("https://nest.examtech.org:8894/verify-payment", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                order_id: response.razorpay_order_id,
                payment_id: response.razorpay_payment_id,
                signature: response.razorpay_signature,
              }),
            })
              .then((response) => response.json())
              .then((data) => {
                if (data.success) {
                  alert("Payment successful");
                } else {
                  alert("Payment verification failed");
                }
              });
          },
          prefill: {
            name: "John Doe",
            email: "john.doe@example.com",
            contact: "9353240388",
          },
          theme: {
            color: "#3399cc",
          },
        };

        const rzp1 = new window.Razorpay(options);
        rzp1.open();
      })
      .catch((error) => {
        console.error("Error:", error);
        setError("Payment failed. Please try again.");
      });
  };

  const handleSubmitOrder = async () => {
    const payload = {
      user_id: 10,
      book_counts: bookCounts,
      payment_method: paymentMethod,
      promo_code: paymentMethod === "Online" ? promoCode : null,
    };

    try {
      const response = await axios.post(
        "https://nest.examtech.org:8894/api/subject-summary",
        payload
      );
      console.log("Order saved:", response.data);
    } catch (err) {
      console.error("Error saving order:", err);
    }
  };

  return (
    <div className="subject-selection container">
      <h2 className="text-center mb-4">
        Select Book Sets or Individual Subjects
      </h2>

      <div className="row">
        <div className="col-md-8">
          <h3 className="section-heading">Book Sets</h3>
          <div className="card-grid">
            {bookSets.map((subject) => (
              <div
                className={`card-container ${
                  selectedCards.includes(subject.name) ? "selected" : ""
                }`}
                key={subject.name}
                onClick={(e) => {
                  if (e.target.tagName !== "INPUT") {
                    handleCardClick(subject);
                  }
                }}
              >
                <img src="/book.jpg" className="card-img" alt={subject.name} />
                <div className="card-content">
                  <h5 className="card-title">{subject.name}</h5>
                  <p className="card-text">₹{subject.price}</p>
                  {selectedCards.includes(subject.name) && (
                    <input
                      type="number"
                      min="1"
                      value={bookCounts[subject.name]}
                      onChange={(e) =>
                        handleBookCountChange(subject.name, e.target.value)
                      }
                      className="form-control mt-2"
                      placeholder="Number of Sets"
                    />
                  )}
                </div>
              </div>
            ))}
          </div>

          <h3 className="section-heading">Single Books</h3>
          <div className="card-grid">
            {singleBooks.map((subject) => (
              <div
                className={`card-container ${
                  selectedCards.includes(subject.name) ? "selected" : ""
                }`}
                key={subject.name}
                onClick={(e) => {
                  if (e.target.tagName !== "INPUT") {
                    handleCardClick(subject);
                  }
                }}
              >
                <img src="/book.jpg" className="card-img" alt={subject.name} />
                <div className="card-content">
                  <h5 className="card-title">{subject.name}</h5>
                  <p className="card-text">₹{subject.price}</p>
                  {selectedCards.includes(subject.name) && (
                    <input
                      type="number"
                      min="1"
                      value={bookCounts[subject.name]}
                      onChange={(e) =>
                        handleBookCountChange(subject.name, e.target.value)
                      }
                      className="form-control mt-2"
                      placeholder="Number of Books"
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="col-md-4">
          <div className="summary-panel">
            <h3>Selected Items</h3>
            {selectedCards.map((subject) => (
              <div key={subject} className="summary-item">
                <span>
                  {subject} ({bookCounts[subject]} x ₹
                  {singleBooks.find((s) => s.name === subject)?.price ||
                    bookSets.find((s) => s.name === subject)?.price}
                  )
                </span>
                <span>
                  ₹
                  {bookCounts[subject] *
                    (singleBooks.find((s) => s.name === subject)?.price ||
                      bookSets.find((s) => s.name === subject)?.price)}
                </span>
              </div>
            ))}
            <hr />
            <p className="total-price">Total Price: ₹{totalPrice}</p>

            <h4>Select Payment Method</h4>
            <button
              className="btn btn-primary"
              onClick={() => {
                setPaymentMethod("Online");
                handlePayment();
              }}
            >
              Pay Online
            </button>
            <button
              className="btn btn-secondary"
              onClick={() => {
                setPaymentMethod("Offline");
                handleSubmitOrder();
              }}
            >
              Pay Offline
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubjectSelection;
