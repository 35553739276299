import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const GoogleLogin = () => {
  const handleLogin = () => {
    const CLIENT_ID =
      "982026444562-k8ft0p3ur1qbf81mv3fp68e98o1pr7a8.apps.googleusercontent.com";
    const REDIRECT_URI = "https://nest.examtech.org/login";
    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=openid email profile`;

    window.location.href = authUrl;
  };

  return (
    <div
      className="container-fluid d-flex align-items-center justify-content-center vh-100"
      style={{ background: "linear-gradient(to bottom, #f0e8ff, #ffffff)" }}
    >
      <div
        className="card p-4 shadow border-0 text-center"
        style={{
          maxWidth: "380px",
          width: "100%",
          borderRadius: "15px",
          backgroundColor: "#fff",
        }}
      >
        {/* Top Image */}
        <img
          src="/book.jfif" // Image from public folder
          alt="Welcome illustration"
          className="img-fluid rounded-top mb-3"
          style={{ borderTopLeftRadius: "15px", borderTopRightRadius: "15px" }}
        />

        <h2 className="fw-bold mb-3" style={{ color: "#5d3fd3" }}>
          Welcome Back!
        </h2>
        <p className="text-muted mb-4" style={{ fontSize: "0.9rem" }}>
          Sign in to continue
        </p>

        <button
          onClick={handleLogin}
          className="btn btn-lg w-100 mb-3"
          style={{
            backgroundColor: "#4285f4",
            color: "#fff",
            fontWeight: "600",
            borderRadius: "8px",
          }}
        >
          Login with Google
        </button>

      </div>
    </div>
  );
};

export default GoogleLogin;
